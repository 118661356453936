import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  DesktopPopupModal,
  Header as GenericHeader,
  HeaderHomePageRightSection,
  HeaderMenuLinkType,
  IconName,
  Icon,
  ActionLink,
  ActionButton,
} from "halifax";
import {
  Switch,
  Route,
  RouteComponentProps,
  matchPath,
} from "react-router-dom";
import {
  B2B_PORTAL_ENDSESSION_PATH,
  CustomEvents,
  DropdownAction,
  EditTravelerModalState,
  RewardsAccount,
  SELECT_EDIT_TRAVELERS,
  SelectEditTravelersProperties,
  VIEWED_CONTACT_MODAL,
  WalletSummaryResponse,
} from "redmond";

import { useUserContext } from "@capone/common";
import { fetchTravelWalletOffers } from "../../../api/v1/takeovers/fetchTravelWalletOffers";
import CapOneLogo from "../../../assets/b2b/capone-logo.svg";
import * as textConstants from "../constants";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TRAVEL_WALLET_CREDIT_EXPERIMENT,
  useExperiments,
  CUSTOMER_PROFILE_EXPERIMENT,
  getExperimentVariantCustomVariants,
  CUSTOMER_PROFILE_VARIANTS,
  CONTROL,
} from "../../../context/experiments";
import { CAP_ONE_LOGO_ALT } from "../../../utils/constants";
import {
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_STAYS,
  PATH_CARS,
  PATH_TRIPS,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_SITE_TERMS_OF_USE,
  PATH_HOME,
  PATH_PREMIER_COLLECTION,
  PATH_TRAVEL_WALLET,
  PATH_TRAVEL_SALE,
  HIDDEN_FUNNEL_ENTRY_HEADER_PATHS,
  PATH_AUTH_INVALID_SESSION,
  PATH_CUSTOMER_PROFILE,
  PATH_PACKAGES,
  PATH_VACATION_RENTALS,
  PATH_EXPERIENCES,
} from "../../../utils/urlPaths";
import { trackEvent } from "../../../api/v1/trackEvent";
import "./styles.scss";
import { SupportModalContent } from "./SupportModal";
import { RewardsAccountSelection } from "../../RewardsAccountSelection";
import { FunnelEntryTabs } from "./FunnelEntryTabs";
import config from "../../../utils/config";
import {
  CUSTOMER_PROFILE_SUBTITLE,
  CUTOMER_PROFILE_TITLE,
  NEW,
} from "../constants";

interface IHeaderProps {
  language: string;
  locationPath: string;
  rewardsAccounts?: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  notAuthenticated?: boolean;
}

const DesktopHeader = ({
  locationPath,
  rewardsAccounts,
  selectedRewardsAccountId,
  setSelectedRewardsAccountId,
  notAuthenticated,
}: IHeaderProps) => {
  return (
    <GenericHeader
      className={clsx("desktop-header", {
        b2b: !notAuthenticated,
        "unprotected-header": notAuthenticated,
      })}
      fullWidth={true}
      left={
        <Switch>
          <Route
            path={[
              PATH_FLIGHTS,
              PATH_HOTELS,
              PATH_STAYS,
              PATH_CARS,
              PATH_TRIPS,
              PATH_TERMS,
              PATH_TERMS_OF_SERVICE,
              PATH_SITE_TERMS_OF_USE,
              PATH_PREMIER_COLLECTION,
              PATH_VACATION_RENTALS,
              PATH_TRAVEL_WALLET,
              PATH_TRAVEL_SALE,
              PATH_CUSTOMER_PROFILE,
              PATH_PACKAGES,
              PATH_EXPERIENCES,
            ]}
            render={(browserRouterProps: RouteComponentProps) => (
              <div
                className="logo-container"
                onClick={() => browserRouterProps.history.push(PATH_HOME)}
              >
                <img
                  src={CapOneLogo}
                  alt={CAP_ONE_LOGO_ALT}
                  aria-label={CAP_ONE_LOGO_ALT}
                />
              </div>
            )}
          />
          <Route path="*" />
        </Switch>
      }
      toolbarClassName="b2b"
      right={
        notAuthenticated ? (
          <Switch>
            <Route
              path={[
                PATH_FLIGHTS,
                PATH_HOTELS,
                PATH_STAYS,
                PATH_CARS,
                PATH_TRIPS,
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_PREMIER_COLLECTION,
                PATH_VACATION_RENTALS,
                PATH_TRAVEL_WALLET,
                PATH_TRAVEL_SALE,
                PATH_PACKAGES,
                PATH_EXPERIENCES,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <CapOneHeaderHomePageRightSection
                  notAuthenticated={notAuthenticated}
                  {...browserRouterProps}
                />
              )}
            />
            <Route path="*" />
          </Switch>
        ) : rewardsAccounts && rewardsAccounts.length > 0 ? (
          <RewardsAccountSelection
            selectedRewardsAccountId={selectedRewardsAccountId}
            setSelectedRewardsAccountId={setSelectedRewardsAccountId}
            rewardsAccounts={rewardsAccounts}
          />
        ) : undefined
      }
      bottom={
        notAuthenticated ? undefined : !HIDDEN_FUNNEL_ENTRY_HEADER_PATHS.includes(
            locationPath
          ) && !locationPath.startsWith(PATH_CUSTOMER_PROFILE) ? (
          <Switch>
            <Route
              path={[
                PATH_FLIGHTS,
                PATH_HOTELS,
                PATH_STAYS,
                PATH_CARS,
                PATH_TRIPS,
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_PREMIER_COLLECTION,
                PATH_VACATION_RENTALS,
                PATH_TRAVEL_WALLET,
                PATH_TRAVEL_SALE,
                PATH_PACKAGES,
                PATH_EXPERIENCES,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <FunnelEntryTabs {...browserRouterProps} />
              )}
            />
            <Route path="*" />
          </Switch>
        ) : undefined
      }
    />
  );
};

interface ICapOneHeaderHomePageRightSectionProps extends RouteComponentProps {
  displayOnlyIcon?: boolean;
  notAuthenticated?: boolean;
  showCustomerProfileNewBadge?: boolean;
}

export const CapOneHeaderHomePageRightSection = (
  props: ICapOneHeaderHomePageRightSectionProps
) => {
  const {
    displayOnlyIcon,
    notAuthenticated,
    showCustomerProfileNewBadge,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const { pathname } = history.location;
  const { sessionInfo, isBusinessEligible } = useUserContext("capone");
  const expState = useExperiments();
  const [openContactModal, setOpenContactModal] = useState(false);
  const [offerCount, setOfferCount] = useState(0);
  const onCarsPage = matchPath(pathname, PATH_CARS);
  const onFlightsPage = matchPath(pathname, PATH_FLIGHTS);
  const onHotelsPage = matchPath(pathname, PATH_HOTELS);
  const onStaysPage = matchPath(pathname, PATH_STAYS);
  const onExperiencesPage = matchPath(pathname, PATH_EXPERIENCES);
  const dropdownActions: DropdownAction[] = [];

  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const isTravelWalletCreditsExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_CREDIT_EXPERIMENT
    ) === AVAILABLE;

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const onLogout = useCallback(() => {
    history.push(B2B_PORTAL_ENDSESSION_PATH);
  }, [history]);

  /**
   * @description Dispatches a custom event to communicate with Pawtucket
   * modules. This event is handled in flights/hotels/cars Body component.
   */
  const onEditTravelerClick = () => {
    let editTravelersModalState = EditTravelerModalState.closed;

    if (onCarsPage) {
      editTravelersModalState = EditTravelerModalState.ground;
    } else if (onFlightsPage) {
      editTravelersModalState = EditTravelerModalState.flight;
    } else if (onHotelsPage) {
      editTravelersModalState = EditTravelerModalState.hotel;
    } else if (onStaysPage) {
      editTravelersModalState = EditTravelerModalState.stays;
    } else if (onExperiencesPage) {
      editTravelersModalState = EditTravelerModalState.experiences;
    }

    const event = new CustomEvent<any>(CustomEvents.editTravelersStateChange, {
      detail: editTravelersModalState,
    });

    document.dispatchEvent(event);

    trackEvent({
      eventName: SELECT_EDIT_TRAVELERS,
      properties: {
        source: "dropdown, header",
      } satisfies SelectEditTravelersProperties,
    });
  };

  const handleSupportModalClose = () => setOpenContactModal(false);

  // only show this action in product pages until edit travelers modal is ready
  // on other pages.
  if (isCustomerProfileExperiment) {
    dropdownActions.push({
      divider: true,
      label: (
        <Box className="customer-profile-header-dropdown-label">
          <Box className="customer-profile-label-text">
            <Box className="customer-profile-label-title">
              <Icon name={IconName.SuitcaseIcon} />
              <Typography className="customer-profile-label-title-text">
                {CUTOMER_PROFILE_TITLE}
              </Typography>
              {showCustomerProfileNewBadge && (
                <Box className="new-feature-label">
                  <Typography className="new-feature-label-text">
                    {NEW}
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography
              className="customer-profile-label-subtitle"
              variant="body1"
            >
              {CUSTOMER_PROFILE_SUBTITLE}
            </Typography>
          </Box>
          <Icon name={IconName.RightChevronIcon} />
        </Box>
      ),

      onClick: () => history.push("/profile"),
    });
  } else {
    if (
      onCarsPage ||
      onFlightsPage ||
      onHotelsPage ||
      onStaysPage ||
      onExperiencesPage
    ) {
      dropdownActions.push({
        divider: true,
        label: "Edit Travelers",
        onClick: onEditTravelerClick,
      });
    }
  }

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const offerCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? offerCount + 1 : offerCount;
      setOfferCount(totalCount);
    });
  }, []);

  const links = useMemo(
    () => [
      ...(isTravelWalletCreditsExperiment
        ? [
            {
              type: HeaderMenuLinkType.Wallet,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  My travel credits and offers
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon: displayOnlyIcon,
            },
          ]
        : isTravelWalletOfferExperiment
        ? [
            {
              type: HeaderMenuLinkType.Offer,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  My travel offers
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon: displayOnlyIcon,
            },
          ]
        : []),
      {
        type: HeaderMenuLinkType.Trips,
        selected: history.location.pathname === PATH_TRIPS,
        content: <Typography className="my-trips-link">My Trips</Typography>,
        onClick: () => {
          routerProps.history.push(PATH_TRIPS);
        },
        dropdownContent: null,
        displayOnlyIcon: displayOnlyIcon,
      },

      {
        type: HeaderMenuLinkType.Support,
        selected: false,
        content: <Typography>{textConstants.SUPPORT_LINK}</Typography>,
        onClick: () => {
          trackEvent({
            eventName: VIEWED_CONTACT_MODAL,
            properties: { contact_reason: "general_contact" },
          });
          setOpenContactModal(true);
        },
        dropdownContent: null,
        displayOnlyIcon: displayOnlyIcon,
      },
      !isBusinessEligible
        ? {
            type: HeaderMenuLinkType.Menu,
            selected: false,
            content: displayOnlyIcon ? (
              <>
                <Icon name={IconName.B2BUser} />
                <Typography>{` ${sessionInfo?.userInfo?.firstName}`}</Typography>
              </>
            ) : (
              <Typography>{`Welcome, ${sessionInfo?.userInfo?.firstName}`}</Typography>
            ),
            onClick: () => {},
            dropdownActions: [
              ...dropdownActions,
              {
                label: <Typography className="log-out-text">Logout</Typography>,
                onClick: onLogout,
              },
            ] as DropdownAction[],
            popoverClassName: isCustomerProfileExperiment
              ? "includes-customer-profile-popover"
              : undefined,
          }
        : {
            type: HeaderMenuLinkType.CorporateMenu,
            selected: false,
            content: displayOnlyIcon ? (
              <>
                <Icon className="dropdown-icon" name={IconName.SuitcaseIcon} />
                <Typography>Personal Travel</Typography>
              </>
            ) : (
              <Typography>{`Welcome, ${sessionInfo?.userInfo?.firstName}`}</Typography>
            ),
            onClick: onLogout,
            dropdownActions: [
              {
                label: `${sessionInfo?.userInfo?.firstName} ${sessionInfo?.userInfo?.lastName}`,
                onClick: isCustomerProfileExperiment
                  ? () => history.push("/profile")
                  : onEditTravelerClick,
              },
            ],
          },
    ],
    [
      displayOnlyIcon,
      dropdownActions,
      history.location.pathname,
      isBusinessEligible,
      isTravelWalletCreditsExperiment,
      isTravelWalletOfferExperiment,
      offerCount,
      onEditTravelerClick,
      onLogout,
      routerProps.history,
      sessionInfo,
    ]
  );

  const UnprotectedPathHeaderRightSection = () => {
    return (
      <Box className="non-auth-header-right-section-container">
        <Box className="non-auth-header-right-section-support">
          <Typography className="support-text">
            {textConstants.QUESTIONS_ABOUT_CAP1_TRAVEL}
          </Typography>
          <ActionLink
            content={
              <Typography className="support-modal-link-text">
                {textConstants.GET_HELP}
              </Typography>
            }
            onClick={() => {
              trackEvent({
                eventName: VIEWED_CONTACT_MODAL,
                properties: { contact_reason: "general_contact" },
              });
              setOpenContactModal(true);
            }}
            className="support-modal-link"
          />
        </Box>
        <ActionButton
          onClick={() => {
            history.push(PATH_AUTH_INVALID_SESSION);
          }}
          message={textConstants.SIGN_IN}
          className="b2b non-auth-login-button"
        />
      </Box>
    );
  };
  return (
    <>
      {notAuthenticated ? (
        <UnprotectedPathHeaderRightSection />
      ) : (
        <HeaderHomePageRightSection
          links={links}
          {...routerProps}
          className="b2b"
          corporatePortalUrl={config.corporatePortalUrl}
          isCustomerProfileExperiment={isCustomerProfileExperiment}
          showCustomerProfileNewBadge={showCustomerProfileNewBadge}
        />
      )}
      <DesktopPopupModal
        open={openContactModal}
        className="desktop-contact-support-popup"
        onClose={handleSupportModalClose}
        invisibleBackdrop={false}
      >
        <Box className="desktop-contact-support-popup-content">
          <SupportModalContent />
        </Box>
      </DesktopPopupModal>
    </>
  );
};

export default DesktopHeader;
